<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';

  const route = useRoute();
  const router = useRouter();
  const isHome = computed(() => route.name === 'home');

  const BurgerModal = defineAsyncComponent(() => import('~/components/modals/burger/Index.vue'));
  const { onReveal: onRevealBurger } = createModal(BurgerModal);

  function goBack() {
    if (window.history.length > 2) {
      router.back();
    } else {
      router.push({ name: 'home' });
    }
  }
</script>

<template>
  <div class="mobile-button">
    <template v-if="isHome">
      <v-button
        class="mobile-button__burger"
        appearance="text"
        color="white"
        size="normal"
        icon="burger"
        rounded
        @click="onRevealBurger()"
      />
    </template>
    <template v-else>
      <v-button
        class="mobile-button__burger"
        appearance="text"
        color="white"
        size="normal"
        icon="arrow-left"
        rounded
        @click="goBack"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .mobile-button {
    &__auth {
      width: fit-content;
      padding: 7px 16px;
      font-size: 12px;
      line-height: 16px;
    }
    &__burger {
      &.v-button[data-size='normal'] {
        padding: 2px 0;
      }
    }
  }
</style>
